<script lang="ts">
	import env_client from "$lib/env/env_clientside";
	import { generate_profile_header_image_url } from "$lib/picture/picture_utils";
	import type { UserAccountDTO } from "$lib/server/entities/user_account/UserAccountEntity";
	import type { UserSessionSettings } from "../../app";
    import { twMerge } from 'tailwind-merge';

    let class_data = twMerge("", $$props.class);

    export let user_account: UserAccountDTO | undefined = undefined;
    export let user_settings: Pick<UserSessionSettings, 'display_name' | 'profile_picture_pathname' | 'profile_picture_url' | 'subscription_status'> | undefined = undefined;

    let profile_picture_path: string | undefined;
    let profile_picture_url: string | undefined;
    let name: string | undefined;
    let is_subscribed;

    // initialize from user_account (if present)
    if (user_account) {

        profile_picture_path = user_account.profile_picture?.path_name;
        profile_picture_url = user_account.profile_picture_url;
        name = user_account.name;
        is_subscribed = user_account.subscription_status === 'paying' || user_account.subscription_status === 'trial';

    } else if (user_settings) {

        profile_picture_path = user_settings.profile_picture_pathname;
        profile_picture_url = user_settings.profile_picture_url
        name = user_settings.display_name;
        is_subscribed = user_settings.subscription_status === 'paying' || user_settings.subscription_status === 'trial'; 

    } else {
        throw new Error("UserAccountAvatar requires either a user_account or user_settings to be passed in.");
    }

    let picture_url: string | undefined;
    if (profile_picture_path) {
        picture_url = generate_profile_header_image_url(profile_picture_path, env_client.s3_endpoints());
    } else if (profile_picture_url) {
        picture_url = profile_picture_url;
    } else {
        picture_url = undefined;
    }

    // is_subscribed = true;
</script>

<div class="avatar_parent {class_data}">

    <div class="avatar" class:subscribed={is_subscribed} class:freemium={!is_subscribed}>       
        {#if picture_url}
    
            <img src="{picture_url}" alt="Profile for {name ?? 'user'}" /> <!--  class="{class_data}" -->
    
        {:else}
    
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" > <!-- class="{class_data}" -->
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
    
        {/if}
    </div>    
</div>

<style>

    .avatar_parent {
        display: inline-block;
        position: relative;
    }

    .avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: relative;
        z-index: 2; 
        padding: 3%;
        display: flex;
        place-items: center;
        place-content: center;
    }

    /* .avatar::before {
        content: '';
        position: absolute;
        top: -5%;
        left: -5%;
        right: -5%;
        bottom: -5%;
        border-radius: 50%;
        z-index: 1;
    } */

    .avatar::after {
        content: '';
        position: absolute;
        top: -2%;
        left: -2%;
        right: -2%;
        bottom: -2%;
        border-radius: 50%;
        z-index: 2; 
        margin: 7%; 
        background-color: white; 
    }

    .subscribed {
        background: conic-gradient(
            #C026D3 0% 12%,
            #701A75 12% 24%,
            #E879F9 24% 36%,
            #A21CAF 36% 48%,
            #4A044E 48% 60%,
            #D946EF 60% 72%,
            #86198F 72% 84%,
            #F0ABFC 84% 100%
        );
    }

    .freemium {
        background: white;
    }

    .avatar img, .avatar svg {
        width: 90%;
        height: 90%;
        border-radius: 50%;
        display: block;
        z-index: 3;
        position: relative;
    }
</style>
  